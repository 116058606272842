import { AES, enc } from 'crypto-js';

export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const removeAllSpecialCharacters = (value: string) => {
  return value.replace(/[^a-zA-Z0-9]/g, '');
};

export const cpfValidate = (cpf: string) => {
  const value = cpf.replace(/[^\d]+/g, '');
  if (value === '') return false;
  if (
    value.length !== 11 ||
    value === '00000000000' ||
    value === '11111111111' ||
    value === '22222222222' ||
    value === '33333333333' ||
    value === '44444444444' ||
    value === '55555555555' ||
    value === '66666666666' ||
    value === '77777777777' ||
    value === '88888888888' ||
    value === '99999999999'
  )
    return false;
  let add = 0;
  for (let i = 0; i < 9; i += 1) add += parseInt(value.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(value.charAt(9), 10)) return false;
  add = 0;
  for (let i = 0; i < 10; i += 1) add += parseInt(value.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(value.charAt(10), 10)) return false;
  return true;
};

export function insertParam(oldURL: string, accessToken: string) {
  if (oldURL.includes('?')) {
    return `${oldURL}&uat=${accessToken}`;
  }

  return `${oldURL}?uat=${accessToken}`;
}

export function Decrypt(word?: string | null, key?: string) {
  if (!word || !key) return null;

  try {
    const decData = enc.Base64.parse(word).toString(enc.Utf8);
    const bytes = AES.decrypt(decData, key).toString(enc.Utf8);
    return JSON.parse(bytes);
  } catch (e) {
    return null;
  }
}

export function redirectPartner(uri: string, accessToken: string) {
  const uriPartner = insertParam(uri, accessToken);
  setTimeout(() => {
    window.location.href = uriPartner;
  }, 3000);
}
