export type headerPropsType = {
  'Content-Type': string;
  'X-Authorization': string;
  Authorization: string;
};

export type authenticationProps = {
  uri: string;
  headerProps: headerPropsType;
};

export async function authentication({ uri, headerProps }: authenticationProps) {
  const requestHeaders = new Headers(headerProps);

  try {
    const response = await fetch(uri, {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      cache: 'default',
    });

    return response.json();
  } catch (e) {
    return e;
  }
}
