import LogotipoAbastece from '../../assets/svg/logo-abastece-ai.svg';
import LogotipoKm from '../../assets/svg/logo-km-vantagens.png';
import './style.css';

const Logo = () => {
  return (
    <div className="img-logo animate">
      <img src={LogotipoAbastece} alt="Logo Abastece-aí" width={75} />
      <span />
      <img src={LogotipoKm} alt="Logo KM de vantagens" width={80} />
    </div>
  );
};

export default Logo;
