import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useToast, useLoading } from 'abastece-ui';
import Login, { DataProps } from './components/Login';
import { authentication } from './utils/api';
import PROPS from './appConfig';
import { Decrypt, redirectPartner, removeAllSpecialCharacters } from './utils';

const App = () => {
  const [searchParams] = useSearchParams();

  const { addToast } = useToast();
  const { addLoading, removeLoading } = useLoading();

  const [oldURI, updateOldURI] = useState(null);
  const [credentials, updateCredentials] = useState(null);

  useEffect(() => {
    const partnerOldURI = Decrypt(searchParams.get('referrer'), process.env.REACT_APP_AAI_AUTHENTICATOR_KEY);
    const partnerCredentials = Decrypt(searchParams.get('aic'), process.env.REACT_APP_AAI_AUTHENTICATOR_KEY);
    if (partnerCredentials && partnerOldURI) {
      updateCredentials(partnerCredentials);
      updateOldURI(partnerOldURI);
    } else {
      addToast(PROPS.home.alerts.alert.type, PROPS.home.alerts.alert.text);
    }
  }, []);

  /**
   *
   * @handleTost
   *
   */

  const handleTost = (payload: any) => {
    if (payload?.access_token && oldURI) {
      addToast(PROPS.home.alerts.success.type, PROPS.home.alerts.success.text);
      redirectPartner(oldURI, payload.access_token);
    } else {
      addToast(PROPS.home.alerts.error.type, payload?.mensagem || PROPS.home.alerts.error.text);
    }
  };

  /**
   *
   *@handleHeaderProps
   *
   */

  const handleHeaderProps = ({ password, cpf }: DataProps) => {
    return {
      'Content-Type': 'application/json',
      'X-Authorization': `Basic ${btoa(`${removeAllSpecialCharacters(cpf)}:${password}`)}`,
      Authorization: `Basic ${btoa(credentials || '')}`,
    };
  };

  /**
   *
   *@handleAuthentication
   *
   */

  const handleAuthentication = async (data: DataProps) => {
    addLoading('Login');
    const response = await authentication({
      uri: `${process.env.REACT_APP_API_URL}/${PROPS.home.endpontRequest}`,
      headerProps: handleHeaderProps(data),
    });

    handleTost(response);
    removeLoading('Login');
  };

  /**
   *
   *return
   *
   */
  return <Login action={data => handleAuthentication(data)} urlPartner={!!credentials} />;
};

export default App;
