import { Container, Col, Row, Input, InputPassword, Text, Button } from 'abastece-ui';
import { useCallback, useState, useEffect } from 'react';
import { cpfMask } from '../../utils';
import PROPS from '../../appConfig';
import Logo from '../Logo';

import './style.css';

type FieldProps = 'cpf' | 'password';

export type DataProps = {
  cpf: string;
  password: string;
};

export type ErrorProps = {
  status: boolean;
  alert: string;
  inputName: FieldProps;
};

export type LoginProps = {
  action: (data: DataProps) => void;
  urlPartner?: boolean;
};

const Login = ({ action, urlPartner = false }: LoginProps) => {
  const [alert, updateAlert] = useState<ErrorProps[]>([
    { status: false, alert: '', inputName: 'cpf' },
    { status: false, alert: '', inputName: 'password' },
  ]);
  const [disabledForm, updateDisabledForm] = useState(true);
  const [data, updateData] = useState({ cpf: '', password: '' });

  const handleError = useCallback(
    (field: FieldProps): ErrorProps | undefined => {
      return alert.find((e: ErrorProps) => e.inputName === field);
    },
    [alert],
  );

  const handleUpdateData = useCallback(
    (field: FieldProps, value: string) => {
      updateData(prevState => ({ ...prevState, [field]: value }));
      updateAlert(prevState =>
        prevState.map((e: ErrorProps) => (e.inputName === field ? PROPS.inputProps[field].validate(value) : e)),
      );
    },
    [data],
  );

  useEffect(() => {
    const validate = alert.every(e => e.status);
    updateDisabledForm(!validate);
  }, [alert]);

  return (
    <div className="wrapper">
      <div className="container">
        <Container>
          {urlPartner ? (
            <Row>
              <Col
                sm={{ offset: '0', size: '12' }}
                md={{ offset: '3', size: '6' }}
                lg={{ offset: '4', size: '4' }}
                className="form"
              >
                <Row>
                  <Col lg="12" justifyContent="center" mt={16}>
                    <Logo />
                  </Col>
                  <Col lg="12" justifyContent="center" mt={24} mb={24}>
                    <Text align="center" as="h1" display="block" variant="title">
                      {PROPS.title}
                    </Text>
                  </Col>
                  <Col lg="12" mb={16}>
                    <Input
                      {...PROPS.inputProps.cpf.props}
                      value={data.cpf}
                      hint={handleError('cpf')?.alert}
                      error={!!handleError('cpf')?.alert}
                      success={handleError('cpf')?.status && !handleError('cpf')?.alert}
                      onChange={e => handleUpdateData('cpf', cpfMask(e.target.value))}
                    />
                  </Col>
                  <Col lg="12">
                    <InputPassword
                      {...PROPS.inputProps.password.props}
                      success={handleError('password')?.status}
                      onChange={e => handleUpdateData('password', e.target.value)}
                    />
                  </Col>
                  <Col lg="12">
                    <Button color="primary" onClick={() => action(data)} size="lg" block disabled={disabledForm}>
                      Entrar
                    </Button>
                  </Col>
                  <Col lg="12" mb={16}>
                    <Text as="small" display="block" variant="bodySm" color="300" align="center">
                      {PROPS.formInfo}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="form">
              <Col sm="12" justifyContent="center" mt={32}>
                <Logo />
              </Col>
              <Col sm="12" justifyContent="center">
                <Text align="center" as="h2" display="block" variant="title">
                  {PROPS.accessDeniedTitle}
                </Text>
              </Col>
              <Col sm="12" justifyContent="center" mb={32}>
                <Text align="center" as="h3" display="block" variant="bodyLg">
                  {PROPS.accessDeniedText}
                </Text>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Login;
