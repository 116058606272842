import { cpfValidate } from './utils';

import { ErrorProps } from './components/Login';

export default {
  title: 'Faça seu login com a conta abastece-aí ou KM de Vantagens',
  home: {
    endpontRequest: 'credentialmanager/api/v1/kmv/parceiro/login',
    alerts: {
      success: {
        type: 'success',
        text: 'Login efetuado com sucesso. Você será redirecionado',
      },
      error: {
        type: 'error',
        text: 'Algo deu errado. Tente novamente mais tarde ou entre em contado com nosso parceiro',
      },
      alert: {
        type: 'alert',
        text: 'Algo deu errado. Entre em contato com o parceiro.',
      },
    },
  },
  accessDeniedTitle: 'Acesso negado',
  accessDeniedText: 'Esse acesso é permitido através dos parceiros abastece-aí ou KM de vantagens.',
  formInfo:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit ipsa mollitia earum pariatur fugit ab necessitatibus fuga rerum, aut minima aliquam ipsam quisquam, ullam eum, iste ratione asperiores voluptate eaque!. Necessitatibus fuga rerum, aut minima aliquam ipsam quisquam, ullam eum, iste ratione asperiores voluptate eaque!',
  inputProps: {
    cpf: {
      props: {
        fullWidth: true,
        id: 'cpf',
        label: 'CPF',
        optText: 'informe seu CPF cadastrado',
        placeholder: 'Ex: 000.000.000-00',
      },
      validate: (data: string): ErrorProps => {
        if (data.length === 14) {
          if (cpfValidate(data)) {
            return { status: true, alert: '', inputName: 'cpf' };
          }
          return { status: false, alert: 'CPF inválido', inputName: 'cpf' };
        }
        return { status: false, alert: '', inputName: 'cpf' };
      },
    },
    password: {
      props: {
        maxLength: 6,
        canSeePassword: true,
        fullWidth: true,
        id: 'password',
        label: 'SENHA',
        placeholder: 'Insira sua senha',
        optText: 'senha do KM de Vantagens',
      },
      validate: (value: string): ErrorProps =>
        value.length === 6
          ? { status: true, alert: '', inputName: 'password' }
          : { status: false, alert: '', inputName: 'password' },
    },
  },
} as const;
